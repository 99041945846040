import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import { QR } from './pages/QR';
import { PaymentSuccess, PaymentError } from './pages/PaymentSuccess';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './App.css';

/* Theme variables */
import './theme/variables.css';
import { Miho } from './components/miho';
import { QRUL } from './pages/QRUL';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home"> 
          <Home />
        </Route>
        <Route exact path="/qr"> 
          <QR />
        </Route>
        <Route exact path="/qrul"> 
          <QRUL />
        </Route>
        <Route exact path="/miho"> 
          <Miho />
        </Route>
        <Route exact path="/paymentSuccess" 
          render={(props) => { return <PaymentSuccess {...props} />}}
        /> 
        <Route exact path="/paymentError"> 
          <PaymentError />
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route path='/android' component={() => {
            window.location.href = 'https://www.rustore.ru/catalog/app/io.ionic.stng'; //'https://play.google.com/store/apps/details?id=io.ionic.stng';
          return null;
        }}/>
        <Route path='/androidul' component={() => {
            window.location.href = 'https://www.rustore.ru/catalog/app/io.stng.lkul'; //'https://play.google.com/store/apps/details?id=io.ionic.stng';
          return null;
        }}/>
        <Route path='/ios' component={() => {
            window.location.href = 'https://apps.apple.com/ru/app/%D1%81%D1%82%D0%BD%D0%B3/id6445904988';
          return null;
        }}/>
        <Route path='/iosul' component={() => {
            window.location.href = 'https://apps.apple.com/ru/app/%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9-%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82-%D1%8E%D1%80-%D0%BB%D0%B8%D1%86-%D0%B0%D0%BE-%D1%81%D1%82%D0%BD%D0%B3/id6478249225';
          return null;
        }}/>
        <Route path='/return' component={() => {
            window.location.href = 'ionic://app.aostng.ru';
          return null;
        }}/>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
